<template>
  <!-- 设备工况 -->
  <div class="equipments-condition none-height flex-grow-1 d-flex flex-column">
    <Backup class="align-self-start"></Backup>
    <!-- 條件查詢 -->
    <div
      class="selector-container d-flex align-items-start section-container dark"
    >
      <el-form :inline="true" style="text-align: left" size="small" class="dark">
        <el-form-item label="通道类型" class="light-label">
          <el-select v-model="queryForm.type" placeholder="通道类型">
            <el-option
              v-for="(channel, index) of channelTypes"
              :key="channel + index"
              :value="channel"
              :label="channel"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="运行状态" class="light-label">
          <el-select v-model="queryForm.manufacturer" placeholder="运行状态">
            <el-option
              v-for="(manufacturer, index) of manufacturerTypes"
              :key="index"
              :label="manufacturer"
              :value="index - 1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit"
          style="color:#25C1FF;border:1px solid #25C1FF;
          background-color: #183D7C;"
          size="mini"
          >查询</el-button>
          <el-button @click="exportTable"
          style="color:#25C1FF;border:1px solid #25C1FF;
          background-color: #183D7C;"
          size="mini"
          >导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table  size="small"
      class="energy-table none-height flex-grow-1 dark"
      :data="tableData"
      height="0"
      style="width: 100%;border:2px solid #1B71CC;"
      ref="table"
      stripe
            :row-class-name="
        (...nu) => {
          return 'dark text-white';
        }
      "
    >
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="SBName" label="设备名称"> </el-table-column>

      <el-table-column prop="SBID" label="设备Id"> </el-table-column>

      <el-table-column prop="ModeType" label="通道类型"> </el-table-column>
      <el-table-column prop="TongDaoBiaoShi" label="通道标识">
      </el-table-column>
      <el-table-column prop="Status" label="运行状态" width="80">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.Status == '离线' ? 'info' : 'success'"
            :effect="skinType ? 'dark' : 'light'"
            size="mini"
          >
            {{ scope.row.Status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="SendBytes" label="发送字节数"></el-table-column>
      <el-table-column prop="ReceiveBytes" label="接收字节数">
      </el-table-column>
      <el-table-column
        prop="UpdateTime"
        label="更新时间"
        width="160"
      ></el-table-column>
    </el-table>
    <div
      class="d-flex section-container dark"
      style="padding: 1rem"
    >
      <el-pagination
        v-if="pageInfo.total"
        class="text-white"
        style="margin-left: auto"
        background
        layout="total, prev, pager, next"
        :total="pageInfo.total"
        :current-page.sync="pageIndex"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getChannelTypes,
  getEquipsCondition,
} from '@/api/equipments/equipments';
import { exportExcel } from '@/utils/utils';
import TurnAskingMixin from '@/mixins/turn-ask';
import Backup from '../utils/backup.vue';

export default {
  name: 'project-equipments-condition',
  components: {
    Backup,
  },
  mixins: [TurnAskingMixin],
  data() {
    return {
      queryForm: { manufacturer: -1, type: '全部' },
      channelTypes: ['全部'],
      manufacturerTypes: ['全部', '正常', '离线'],
      pageInfo: {
        index: 1,
        size: 10,
        total: 0,
      },
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      tableData: [],
      pageIndex: 1,
    };
  },
  watch: {
    queryForm: {
      deep: true,
      handler() {
        if (this.pageIndex !== 1) {
          this.pageIndex = 1;
          return;
        }
        this.requestEquipsCondition();
      },
    },
    selectProject() {
      this.requestEquipsCondition();
    },
  },
  created() {
    // 获取通道名称
    this.requestChanelTypes();
    this.requestEquipsCondition();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    currentChangeListener(no) {
      this.pageIndex = no;
    },
    requestChanelTypes() {
      getChannelTypes().then((res) => {
        this.channelTypes = ['全部'].concat(res);
        [this.queryForm.type] = this.channelTypes;
      });
    },
    requestEquipsCondition() {
      console.log(this.queryForm);
      if (!this.selectProject) {
        return;
      }
      getEquipsCondition({
        ProjectIdStr: this.selectProject.IdStr,
        ModeType: this.queryForm.type === '全部' ? null : this.queryForm.type,
        Status:
          this.queryForm.manufacturer === -1
            ? null
            : this.queryForm.manufacturer,
        isExport: null,
        Page: this.pageIndex,
        Rows: this.pageInfo.size,
      }).then((res) => {
        if (res === null) {
          this.tableData = [];
          this.pageInfo.total = 0;
          return;
        }
        this.pageInfo.total = res.total || 0;
        this.tableData = res.list || res || [];
      }).catch(() => {
        this.tableData = [];
        this.pageInfo.total = 0;
      }).then(() => {
        this.turnAsking({
          routeName: this.routeName,
          nowRouteName: this.$route.name,
          func: this.requestEquipsCondition,
        }, 5000);
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    onSubmit() {
      this.requestEquipsCondition();
    },
    exportTable() {
      exportExcel(this.$refs.table.$el);
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-table.dark th, .el-table.dark thead.is-group th {
  color:#8696BF;
}
</style>
